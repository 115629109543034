<template>
<div class="wrapper">

    <h2>Filter Roles (acts on all data)</h2>
    <v-card class='filterCard'>
        <v-container fluid>
            <v-row align="center">
                <v-col cols="12" sm="12">
                    <v-select
                    v-model="filter_roles"
                    :items="filter_items"
                    label="Filter Roles"
                    chips
                    multiple
                    :loading="this.fetching.active"
                    ></v-select>
                </v-col>
            </v-row>
        </v-container>
    </v-card>

    <br>
    <h2>Survey Data</h2>
    <v-card>
        <v-container fluid>
            <!-- Pre or Post Meeting Filter -->
            <v-row align="center">
                <v-col cols="12" sm="12">
                    <v-select
                    v-model="filter_preOrPost_selected"
                    :items="filter_preOrPost_items"
                    label="PRE or POST Meeting Survey"
                    chips
                    multiple
                    ></v-select>
                </v-col>
            </v-row>

            <div class="surveyDataCards">
                <!-- Survey Data by Event -->
                <div class="side">
                    <v-row align="center">
                        <v-col cols="12" sm="12">
                            <v-select
                            v-model="filter_surveyDataByEvent_selected"
                            :items="filter_surveyDataByEvent_items"
                            label="Survey Data by Event"
                            chips
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-btn @click="createDownload_surveyResultsByEvent()">Download by Event</v-btn>
                </div>

                <!-- Survey Data by Question Set -->
                <div class="side">
                    <v-row align="center">
                        <v-col cols="12" sm="12">
                            <v-select
                            v-model="filter_surveyDataByQuestionSet_selected"
                            :items="filter_surveyDataByQuestionSet_items"
                            label="Survey Data by Question Set"
                            chips
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-btn @click="createDownload_surveyResultsByQuestionSet()">Download by Question Set</v-btn>
                </div>
            </div>

        </v-container>
    </v-card>

    <!-- Discussion Forum Posts -->
    <br>
    <h2>Discussion Forum Posts / User Engagement / User List</h2>
    <v-card>
        <v-btn @click="download_table(discussionPosts_table, 'discussionForumPosts')">Download Discussion Forum Posts</v-btn>
        <v-btn @click="download_table(userEngagement_table, 'userEngagementTable')">Download User Engagement Table</v-btn>
        <v-btn @click="download_table(userListTable, 'userListTable')">Download User List</v-btn>
    </v-card>

</div>
</template>

<script>
export default {
    data: function(){
        return {
            discussionPosts_table: [
                // Table header
                [
                    'Topic Id',
                    'Topic Title',
                    'Message',
                    'Username',
                    'State',
                    'Created',
                    'Updated'
                ]
            ],
            fetching: {
                active: false,
                filterForSending: []
            },

            filter_items: ['user', 'client', 'SuperAdmin', 'admin', 'tester'],
            filter_roles: ['user', 'client'],

            filter_preOrPost_items: ['Pre', 'Post'],
            filter_preOrPost_selected: ['Pre', 'Post'],

            filter_surveyDataByEvent_items: [],
            filter_surveyDataByEvent_selected: null,

            filter_surveyDataByQuestionSet_items: [],
            filter_surveyDataByQuestionSet_selected: null,

            payload: {},

            surveyDataByEvent_obj: null,
            surveyDataByQuestionSet_obj: null,

            userEngagement_obj: {},
            userEngagement_arr: [],
            userEngagement_table: [
                // Table header
                [
                    'User Name',
                    'Last Login',
                    'Pre-meeting Surveys Submitted',
                    'Post-meeting Surveys Submitted',
                    'Posts in Discussion Board',
                    'Pre-meeting Materials Downloaded/Viewed',
                    'Videos Watched'
                ]
            ],
            userListTable: [
                // Table header
                [
                    'Given Name',
                    'Family Name',
                    'Email',
                    'Affiliation',
                    'Specialty',
                    'Profession',
                    'Timezone',
                    'Province',
                    'Country'
                ]
            ]
        }
    },
    created: async function(){
        await this.pass_dataDumpData_toPayload();
        // console.log('created payload', JSON.parse(JSON.stringify(this.payload)));
    },
    methods: {
        create_csvString_from2dArray: function(table){
            return new Promise((resolve, reject)=>{

                // let csvString = 'data:text/csv;charset=utf-8,'; // start string with csv file header declarations
                let csvString = 'data:text/csv;charset=utf-8,\uFEFF'; // start string with csv file header declarations (the '\uFEFF' makes a difference. Displays things like ≤ properly)

                if( table === null || table === undefined || !table.length){
                    console.log('ERROR IN create_csvString_from2dArray(table): table argument expects an array, but found null, undefined, a number, an object, or has no length.')
                    resolve(false);
                    return;
                };

                for(let r = 0; r < table.length; r++){ // traverse rows of the table
                    let row = table[r];

                    if( !row.length              // row array has no length
                    &&  r === table.length - 1){ // AND End of table traversal
                        resolve(csvString);
                    }
                    else
                    if( !row.length){ // row array has no length
                        // SET: newline onto csvString
                        csvString += '\n';
                    }
                    else{
                        for(let c = 0; c < row.length; c++){ // traverse cols of the row
                            let col = row[c];

                            // MODIFY: col into properly formated colString
                            let colString = `${col}`; // Ensure working with a string
                                colString = colString.replace(/(\r\n|\n|\r)/gm, '').replace(/(\s\s)/gm, ' '); // Clean text to remove multiple spaces and jumpline (which breaks csv)
                                // colString = colString.replace(/"/g, '""'); // Escape double-quote with double-double-quote (see https://stackoverflow.com/questions/17808511/properly-escape-a-double-quote-in-csv)
                            // MODIFY: colString into uriEncodedString
                            let uriEncodedString = encodeURI(colString);                    // native URI encoding method
                                uriEncodedString = uriEncodedString.replaceAll('#', '%23'); // hashmarks replaced (breaks csv)
                                uriEncodedString = uriEncodedString.replaceAll("'", '%27'); // single quotes replaced (breaks csv)
                                uriEncodedString = uriEncodedString.replaceAll("_", '%5f'); // underscores replaced (breaks csv)
                                uriEncodedString = uriEncodedString.replaceAll(",", '');    // commas replaced (breaks csv, as it's column separator symbol)

                            // CREATE: colStringWithSeparator
                            let colSeparator = ',';
                            let colStringWithSeparator = `${colSeparator}${uriEncodedString}`

                            // SET: colStringWithSeparator onto csvString
                            csvString += colStringWithSeparator;

                            if( r === table.length - 1 // End of table traversal
                            &&  c === row.length - 1){ // AND End of row traversal
                                resolve(csvString);
                            }
                            else
                            if( c === row.length - 1){ // End of row traversal
                                // SET: newline onto csvString
                                csvString += '\n';
                            };
                        };
                    };
                };
            });
        },
        create_surveyTable: async function(surveyObjsArray, optionalFixDuplicates){
            return new Promise(async(resolve, reject)=>{

                let table = [
                    // Header row (aka table[0])
                    [
                        'Event Id',
                        'Event Title',
                        'Question Set Id',
                        'Question Set Title',
                        'Version',
                        'Questions Id',
                        'Survey Type',
                        'Submitted',
                        'User Id'
                    ]
                ];

                // RESOLVE table & RETURN if no data to work on
                if( surveyObjsArray.length === 0){
                    resolve(table);
                    return;
                };

                // SET: headerMade flag to control only creating the header once
                let headerMade = false;

                for(let i = 0; i < surveyObjsArray.length; i++){
                    let obj           = surveyObjsArray[i];
                    let questionsData = JSON.parse(obj.questions_data);
                    let answersData   = JSON.parse(obj.surveyAnswers_data).questions;

                    /*
                    There was bad data during development, where questions and answers did no align.
                    This code here is just to log this peculiar case.
                    Not sure if it is actionable or will be of use.
                    */
                    let questionsDataLength = questionsData.length;
                    let answersDataLength   = Object.keys(answersData).length;
                    if( questionsDataLength != answersDataLength){
                        console.log("Found a discrepancy between the amount of questions and answers.");
                    };

                    // SET: headerMade flag true after 1st iteration
                    if(i > 0){
                        headerMade = true;
                    };

                    /*
                    CREATE: start of row
                    */
                    let event_id          = obj.event_id;
                    let event_title       = '';
                    if( JSON.parse(obj.event_title) != null){
                        event_title       = JSON.parse(obj.event_title)['en-ca'];
                    };
                    let questionSet_id    = obj.question_set_id;
                    let questionSet_title = JSON.parse(obj.question_set_title)['en-ca'];
                    let version           = obj.version;
                    let questions_id      = obj.questions_id;
                    let survey_type       = obj.survey_type
                    let submitted         = obj.created;
                    let user_id           = obj.user_id;

                    // REPLACE: user_id with full name
                    for(let ii = 0; ii < this.payload.users.length; ii++){
                        let user_obj = this.payload.users[ii];
                        let username = user_obj.Username;

                        if( user_id == username){
                            let name_given  = null;
                            let name_family = null;
                            let name_full   = null;

                            let attributes_arr = user_obj.Attributes;
                            for(let iii = 0; iii < attributes_arr.length; iii++){
                                let attribute_obj = attributes_arr[iii];

                                if( attribute_obj.Name == 'given_name'){
                                    name_given = attribute_obj.Value;
                                }
                                else
                                if( attribute_obj.Name == 'family_name'){
                                    name_family = attribute_obj.Value;
                                };

                                if( iii === attributes_arr.length - 1){ // end of loop
                                    // END OF LOOP: replace user_id with full name
                                    name_full = `${name_given} ${name_family}`;
                                    user_id   = name_full;
                                };
                            };
                        };
                    };

                    let row = [
                        event_id,
                        event_title,
                        questionSet_id,
                        questionSet_title,
                        version,
                        questions_id,
                        survey_type,
                        submitted,
                        user_id
                    ];
                    /*
                    END CREATE: start of row
                    */

                    table = await this.loop_questionsData(questionsData, table, headerMade, row, answersData);

                    if( i === surveyObjsArray.length - 1){ // end of loop
                        table = await this.fixTable_byNotKeepingRepeatedData(table);
                        table = await this.remove_preOrPostByFilter(table);
                        resolve(table);
                    };
                };
            });
        },
        createDownload_surveyResultsByEvent: async function(){

            let key             = this.filter_surveyDataByEvent_selected.split('_')[0];
            let surveyObjsArray = this.surveyDataByEvent_obj[key];
            let table           = await this.create_surveyTable(surveyObjsArray, 'fixDuplicates');
            // console.log('TABLE', table);

            /*
            CATCH: only a header row is present.
            This means no data after preOrPost filter exclusion.
            ALERT user and RETURN before making CSV
            */
            if( table.length === 1){
                alert(`Survey not found`)
                return;
            };

            let CSVString = await this.create_csvString_from2dArray(table);

            let filterRoles = this.filter_roles.join('-');

            this.download_CSVFile_fromCSVString(CSVString, `CanClear_questionSet_${this.filter_surveyDataByEvent_selected}_${filterRoles}_${new Date().getFullYear()}-${new Date().getMonth()}-${new Date().getDate()}_T${new Date().getHours()}-${new Date().getMinutes()}-${new Date().getSeconds()}`);
        },
        createDownload_surveyResultsByQuestionSet: async function(){

            let key             = this.filter_surveyDataByQuestionSet_selected.split('_')[0];
            let surveyObjsArray = this.surveyDataByQuestionSet_obj[key];
            let table           = await this.create_surveyTable(surveyObjsArray);

            /*
            CATCH: only a header row is present.
            This means no data after preOrPost filter exclusion.
            ALERT user and RETURN before making CSV
            */
            if( table.length === 1){
                alert(`Survey not found`)
                return;
            };

            let CSVString = await this.create_csvString_from2dArray(table);

            let filterRoles = this.filter_roles.join('-');

            this.download_CSVFile_fromCSVString(CSVString, `CanClear_questionSet_${this.filter_surveyDataByQuestionSet_selected}_${filterRoles}_${new Date().getFullYear()}-${new Date().getMonth()}-${new Date().getDate()}_T${new Date().getHours()}-${new Date().getMinutes()}-${new Date().getSeconds()}`);
        },
        download_CSVFile_fromCSVString: function(CSVString, filename = 'filename'){
            // CREATE: link HTML
            let link = `<a id='csvLink' href='${CSVString}' download='${filename}.csv'>`;
            // GIVE: link HTML to body
            document.body.insertAdjacentHTML('afterend', link);
            // GET: link on body
            let csvLink = document.querySelector('#csvLink');
            // CLICK: link to download
            csvLink.click();
            // REMOVE: link element
            csvLink.remove();
        },
        download_table: async function(table, tableName){
            let CSVString = await this.create_csvString_from2dArray(table);
            let filterRoles = this.filter_roles.join('-');
            this.download_CSVFile_fromCSVString(CSVString, `CanClear_${tableName}_${filterRoles}_${new Date().getFullYear()}-${new Date().getMonth()}-${new Date().getDate()}_T${new Date().getHours()}-${new Date().getMinutes()}-${new Date().getSeconds()}`);
        },
        fixTable_byNotKeepingRepeatedData: function(table){
            return new Promise((resolve, reject)=>{
                // CREATE: arrays for fixedTable and rowIdentifiers
                let fixedTable = [];
                let rowIdentifiers = [];
                // LOOP: table
                for(let i = 0; i < table.length; i++){
                    let row = table[i];
                    // CREATE: rowIdentifierString from certain row fields
                    let questionSetId = row[2];
                    let submitted     = row[7];
                    let userId        = row[8];
                    let identifierString = `${questionSetId}${submitted}${userId}`;
                    // IS: rowIdentifiers array doesn't yet include the identifierString
                    if( rowIdentifiers.includes(identifierString) === false){
                        // SET: identifierString into rowIdentifiers array
                        rowIdentifiers.push(identifierString);
                        // SET: row into fixedTable array
                        fixedTable.push(row);
                    };
                    // END OF LOOP
                    if( i === table.length - 1){
                        resolve(fixedTable);
                    };
                };
            });
        },
        loop_choices: async function(questionChoices_arr, table, headerMade, row, answersData, question_id, group_id){
            return new Promise(async(resolve, reject)=>{

                for(let i = 0; i < questionChoices_arr.length; i++){
                    let choice_obj   = questionChoices_arr[i];
                    let choice_text  = `Choice Text: ${choice_obj.text['en-ca']}`;
                    let choice_value = choice_obj.value;

                    if( headerMade == false){
                        table[0].push(choice_text); // header
                    };

                    if( answersData[question_id] != undefined){
                        let answer = answersData[question_id].group[group_id].value;
                        if( choice_value == answer){
                            row.push('1');
                        }
                        else{
                            row.push('');
                        };
                    }
                    else{
                        row.push('');
                    };

                    if( i === questionChoices_arr.length - 1){ // end of loop
                        table.push(row);
                        resolve(table);
                    };
                };
            });
        },
        loop_questionGroups: async function(questionGroups_arr, table, headerMade, row, answersData, question_id){
            return new Promise(async(resolve, reject)=>{

                for(let i = 0; i < questionGroups_arr.length; i++){
                    let group_obj           = questionGroups_arr[i];
                    let group_id            = group_obj.id;
                    let groupId_text        = `Group Id: ${group_id}`;
                    let questionChoices_arr = group_obj.choices;

                    if( headerMade == false){
                        table[0].push(group_id); // header
                    };

                    row.push('');

                    table = await this.loop_choices(questionChoices_arr, table, headerMade, row, answersData, question_id, group_id);

                    if( i === questionGroups_arr.length - 1){ // end of loop
                        resolve(table);
                    };
                };
            });
        },
        loop_questionsData: async function(questionsData, table, headerMade, row, answersData){
            return new Promise(async(resolve, reject)=>{

                for(let ii = 0; ii < questionsData.length; ii++){
                    let question_obj       = questionsData[ii];
                    let question_id        = question_obj.id;
                    let questionId_text    = `Question Id: ${question_id}`;
                    let question_text      = `Question Text: ${question_obj.body.text['en-ca']}`;
                    let questionGroups_arr = question_obj.groups;

                    if( headerMade == false){
                        table[0].push(question_id, question_text); // header
                    };

                    row.push('','');

                    table = await this.loop_questionGroups(questionGroups_arr, table, headerMade, row, answersData, question_id);

                    if( ii === questionsData.length - 1){ // end of loop
                        resolve(table)
                    };
                };
            });
        },
        pass_dataDumpData_toPayload: async function(){
            return new Promise(async(resolve, reject)=>{

                let self = this;
                let url = '/api/metrics';
                let sendObj = {
                    filter_roles: this.filter_roles,
                    page: 'dataDump'
                };

                // IF no filter roles, then zero metrics and return
                if( this.filter_roles.length === 0){

                    if( this.fetching.active === true){
                        resolve();
                        return;
                    }
                    else
                    if( this.fetching.active === false){
                        // RESET: dataDumpData
                        await this.reset_dataDumpData();
                        resolve();
                        return;
                    };
                }
                else{

                    if( this.fetching.active === true){
                        resolve();
                        return;
                    }
                    else
                    if( this.fetching.active === false){
                        // STATE ON
                        this.fetching.active = true;

                        // SAVE filtersForSending
                        this.fetching.filtersForSending = JSON.parse(JSON.stringify(this.filter_roles)); // stringify and parse to break reference

                        self.sendRequest('POST', url, sendObj).then(async(response)=>{

                            this.payload = response.data;

                            // STATE OFF
                            this.fetching.active = false;

                            let answer = is_sameInArray(this.filter_roles, this.fetching.filtersForSending);
                            if( answer === false){
                                resolve();
                                // RESET: dataDump
                                await this.reset_dataDumpData();
                                // PASS: dataDumpData to payload data
                                await this.pass_dataDumpData_toPayload();
                            }
                            else{
                                resolve();
                            };
                        });
                    };
                };
            });
            // END promise

            function is_sameInArray(arr_1, arr_2){
                // Arrays NOT same length
                if( arr_1.length !== arr_2.length){
                    return false;
                };
                // Loop to compare arrays
                for(let i = 0; i < arr_1.length; i++){
                    let item = arr_1[i];
                    // Failed to find arr_1 item in arr_2
                    if( arr_2.includes(item) === false){
                        return false;
                    };
                    // Got to end of loop without returning false
                    if( i === arr_1.length - 1){ // end of loop
                        return true;
                    };
                };
            };
        },
        remove_preOrPostByFilter: function(table){
            return new Promise((resolve, reject)=>{

                let filter_preOrPost = this.filter_preOrPost_selected.map(item =>{ return `${item}MeetingSurvey`});

                let targetColumnIndex = null;

                let headerRow = table[0];
                for(let i = 0; i < headerRow.length; i++){
                    let column = headerRow[i];

                    if( column == 'Survey Type'){
                        targetColumnIndex = i;
                    };

                    if( i === headerRow.length - 1){ // end of loop headerRow
                        /*
                        Loop table backwards because going to be removing rows.
                        And terminate at > 0 to exclude the headerRow.
                        */
                        for(let ii = table.length -1; ii > 0; ii--){
                            let cell = table[ii][targetColumnIndex];

                            if( filter_preOrPost.includes(cell) == false){
                                table.splice(ii, 1);
                            };

                            if( ii === 1){ // end of loop at 1; not getting to 0.
                                resolve(table);
                            };
                        };
                    };
                };
            });
        },
        reset_dataDumpData: function(){
            return new Promise((resolve, reject)=>{
                // RESET: discussionPostsTable
                this.discussionPosts_table = [
                    // Table header
                    [
                        'Topic Id',
                        'Topic Title',
                        'Message',
                        'Username',
                        'State',
                        'Created',
                        'Updated'
                    ]
                ];
                // RESET: survey data objects
                this.surveyDataByEvent_obj = null;
                this.surveyDataByQuestionSet_obj = null;
                // RESET: userEngagement object, array, and table
                this.userEngagement_obj = {};
                this.userEngagement_arr = [];
                this.userEngagement_table = [
                    // Table header
                    [
                        'User Name',
                        'Last Login',
                        'Pre-meeting Surveys Submitted',
                        'Post-meeting Surveys Submitted',
                        'Posts in Discussion Board',
                        'Pre-meeting Materials Downloaded/Viewed',
                        'Videos Watched'
                    ]
                ];
                // RESET: userListTable
                this.userListTable = [
                    // Table header
                    [
                        'Given Name',
                        'Family Name',
                        'Email',
                        'Affiliation',
                        'Specialty',
                        'Profession',
                        'Timezone',
                        'Province',
                        'Country'
                    ]
                ];
                resolve();
            });
        },
        set_discussionForumPostsTable_fromPayload: function(){
            return new Promise((resolve, reject)=>{

                let discussionPosts = this.payload.postsInDiscussionBoard;

                // RESOLVE/RETURN if no data to work on
                if( discussionPosts.length === 0){
                    resolve();
                    return;
                };

                for(let i = 0; i < discussionPosts.length; i++){
                    let post = discussionPosts[i];

                    let topic_id   = post.topic_id
                    let post_title = JSON.parse(post.title)['en-ca'];
                    let message    = post.message;
                    let username   = post.username;
                    let state      = post.state;
                    let created    = post.created;
                        created    = new Date(created);
                        created.toString();
                    let updated    = post.updated;
                        updated    = new Date(updated);
                        updated.toString();

                    this.discussionPosts_table.push([
                        topic_id,
                        post_title,
                        message,
                        username,
                        state,
                        created,
                        updated
                    ]);

                    if( i === discussionPosts.length - 1){ // end of loop
                        resolve();
                    };
                };
            });
        },
        set_surveyData_byEventAndQuestionSet_fromPayload: function(){
            return new Promise((resolve, reject)=>{

                let byEvent = {};
                let byQuestionSet = {};

                let surveyData = this.payload.surveyData;

                // RESOLVE/RETURN if no data to work on
                if( surveyData.length === 0){
                    resolve();
                    return;
                };

                for(let i = 0; i < surveyData.length; i++){
                    let surveyDataObj = surveyData[i];
                    let eventId       = surveyDataObj.event_id;
                    let questionSetId = surveyDataObj.question_set_id;

                    // CREATE: bucket on byEvent obj if not there already
                    if( byEvent.hasOwnProperty(eventId) === false){
                        byEvent[eventId] = [];
                    };
                    // SET: into correct byEvent bucket
                    byEvent[eventId].push(surveyDataObj);

                    // CREATE: bucket on byQuestionSet obj if not there already
                    if( byQuestionSet.hasOwnProperty(questionSetId) === false){
                        byQuestionSet[questionSetId] = [];
                    };
                    // SET: into correct byQuestionSet bucket
                    byQuestionSet[questionSetId].push(surveyDataObj);

                    if( i === surveyData.length - 1){ // end of loop
                        this.surveyDataByEvent_obj = byEvent;
                        this.surveyDataByQuestionSet_obj = byQuestionSet;
                        resolve();
                    };
                };
            });
        },
        set_surveyFilters_fromSurveyDataObjs: function(){

            let surveyDataByEvent_obj       = this.surveyDataByEvent_obj;
            let surveyDataByQuestionSet_obj = this.surveyDataByQuestionSet_obj;

            /****************************************
            CREATE & SET: Survey Data by Event Filter
            *****************************************/
            let i = 0;
            for(let key in surveyDataByEvent_obj){
                let obj = surveyDataByEvent_obj[key];

                let eventTitle = JSON.parse(obj[0].event_title);
                if( eventTitle == null){ // handle potential null
                    eventTitle = 'null';
                }
                else{
                    eventTitle = eventTitle['en-ca']
                };
                // CREATE: eventKey from [key & title]
                let eventKey = `${key}_${eventTitle}`;
                // SET: eventKey in items
                this.filter_surveyDataByEvent_items.push(eventKey);
                // SET: default selected as 1st option
                if( i === 0){
                    this.filter_surveyDataByEvent_selected = eventKey;
                };

                i++;
                if( i === Object.keys(surveyDataByEvent_obj).length){ //end of loop

                };
            };

            /***********************************************
            CREATE & SET: Survey Data by Question Set Filter
            ************************************************/
            let ii = 0;
            for(let key in surveyDataByQuestionSet_obj){
                let obj = surveyDataByQuestionSet_obj[key];

                let questionSetTitle = JSON.parse(obj[0].question_set_title);
                if( questionSetTitle == null){ // handle potential null
                    questionSetTitle = 'null';
                }
                else{
                    questionSetTitle = questionSetTitle['en-ca']
                };
                // CREATE: questionSetKey from [key & title]
                let questionSetKey = `${key}_${questionSetTitle}`;
                // SET: questionSetKey in items
                this.filter_surveyDataByQuestionSet_items.push(questionSetKey);
                // SET: default selected as 1st option
                if( ii === 0){
                    this.filter_surveyDataByQuestionSet_selected = questionSetKey;
                };

                ii++;
                if( ii === Object.keys(surveyDataByQuestionSet_obj).length){ //end of loop

                };
            };
        },
        set_userEngagementArr_fromUserEngagementObj: function(){
            let self = this;
            return new Promise((resolve, reject)=>{

                // RESOLVE/RETURN if no data to work on
                if( Object.keys(self.userEngagement_obj).length === 0){
                    resolve();
                    return;
                };

                let i = 0;
                for(let key in self.userEngagement_obj){
                    let obj = self.userEngagement_obj[key];

                    self.userEngagement_arr.push(obj);

                    i++;
                    if( i === Object.keys(self.userEngagement_obj).length){ //end of loop
                        resolve();
                    };
                };
            });
        },
        set_userEngagementObj_fromPayload: async function(){
            let self = this;

            return new Promise(async(resolve, reject)=>{
                Promise.all([
                    give_userIdObjWithUserName_toUserEngagementObj(),
                    give_lastLogin_toUserEngagementObj(),
                    give_preMeetingSurveys_toUserEngagementObj(),
                    give_postMeetingSurveys_toUserEngagementObj(),
                    give_postsInDiscussionBoard_toUserEngagementObj(),
                    give_preMeetingMaterials_toUserEngagementObj(),
                    give_videosWatched_toUserEngagementObj()
                ]);

                resolve();
            });

            // internal functions
            function give_lastLogin_toUserEngagementObj(){
                return new Promise((resolve, reject)=>{

                    // RESOLVE & RETURN on no length
                    if( self.payload.lastLogin.length === 0){
                        resolve();
                        return;
                    };

                    for(let i = 0; i < self.payload.lastLogin.length; i++){
                        let lastLogin_obj = self.payload.lastLogin[i];
                        let userId = lastLogin_obj.userId;

                        if( Object.keys(self.userEngagement_obj).includes(userId)){

                            let dateTime = new Date(lastLogin_obj.lastLogin);
                                dateTime.toString();

                            self.userEngagement_obj[userId].lastLogin = dateTime;
                        };

                        if( i === self.payload.lastLogin.length - 1){ // end of loop
                            resolve();
                        };
                    };
                });
            };

            function give_postMeetingSurveys_toUserEngagementObj(){
                new Promise(function(resolve, reject) {

                    // RESOLVE & RETURN on no length
                    if( self.payload.individual_postMeetingSurveys.length === 0){
                        resolve();
                        return;
                    };

                    for(let i = 0; i < self.payload.individual_postMeetingSurveys.length; i++){
                        let postMeetingSurvey_obj = self.payload.individual_postMeetingSurveys[i];
                        let userId = postMeetingSurvey_obj.userId;

                        if( Object.keys(self.userEngagement_obj).includes(userId)){
                            self.userEngagement_obj[userId].postMeetingSurveys = postMeetingSurvey_obj.amount;
                        };

                        if( i === self.payload.individual_postMeetingSurveys.length - 1){ // end of loop
                            resolve();
                        };
                    };
                });
            };

            function give_postsInDiscussionBoard_toUserEngagementObj(){
                return new Promise((resolve, reject)=>{

                    // RESOLVE & RETURN on no length
                    if( self.payload.discussionForumMessages_perUser.length === 0){
                        resolve();
                        return;
                    };

                    for(let i = 0; i < self.payload.discussionForumMessages_perUser.length; i++){
                        let obj = self.payload.discussionForumMessages_perUser[i];
                        let userId = obj.user_id;

                        if( Object.keys(self.userEngagement_obj).includes(userId)){
                            self.userEngagement_obj[userId].postsInDiscussionBoard = obj.messageCount;
                        };

                        if( i === self.payload.discussionForumMessages_perUser.length - 1){ // end of loop
                            resolve();
                        };
                    };
                });
            };

            function give_preMeetingMaterials_toUserEngagementObj(){
                new Promise(function(resolve, reject) {

                    // RESOLVE & RETURN on no length
                    if( self.payload.individual_preMeetingMaterialDownloadedViewed.length === 0){
                        resolve();
                        return;
                    };

                    for(let i = 0; i < self.payload.individual_preMeetingMaterialDownloadedViewed.length; i++){
                        let preMeetingMaterials_obj = self.payload.individual_preMeetingMaterialDownloadedViewed[i];
                        let userId = preMeetingMaterials_obj.userId;

                        if( Object.keys(self.userEngagement_obj).includes(userId)){
                            self.userEngagement_obj[userId].preMeetingMaterials = preMeetingMaterials_obj.amount;
                        };

                        if( i === self.payload.individual_preMeetingMaterialDownloadedViewed.length - 1){ // end of loop
                            resolve();
                        };
                    };
                });
            };

            function give_preMeetingSurveys_toUserEngagementObj(){
                new Promise(function(resolve, reject) {

                    // RESOLVE & RETURN on no length
                    if( self.payload.individual_preMeetingSurveys.length === 0){
                        resolve();
                        return;
                    };

                    for(let i = 0; i < self.payload.individual_preMeetingSurveys.length; i++){
                        let preMeetingSurvey_obj = self.payload.individual_preMeetingSurveys[i];
                        let userId = preMeetingSurvey_obj.userId;

                        if( Object.keys(self.userEngagement_obj).includes(userId)){
                            self.userEngagement_obj[userId].preMeetingSurveys = preMeetingSurvey_obj.amount;
                        };

                        if( i === self.payload.individual_preMeetingSurveys.length - 1){ // end of loop
                            resolve();
                        };
                    };
                });
            };

            function give_userIdObjWithUserName_toUserEngagementObj(){
                return new Promise((resolve, reject)=>{

                    // RESOLVE & RETURN on no length
                    if( self.payload.users.length === 0){
                        resolve();
                        return;
                    };

                    for(let i = 0; i < self.payload.users.length; i++){
                        let user_obj = self.payload.users[i];
                        let userId = user_obj.Username;

                        // CREATE: userId object onto userEngagement_obj
                        self.userEngagement_obj[userId] = {
                            userName: '',
                            lastLogin: '',
                            preMeetingSurveys: 0,
                            postMeetingSurveys: 0,
                            postsInDiscussionBoard: 0,
                            preMeetingMaterials: 0,
                            videosWatched: 0
                        };

                        // GIVE: userName to userEngagement_obj[userId] AS [givenName + familyName]
                        let userName = null;
                        let givenName = null;
                        let familyName = null;
                        let attributes_arr = user_obj.Attributes;
                        for(let ii = 0; ii < attributes_arr.length; ii++){
                            let attribute = attributes_arr[ii];

                            if( attribute.Name == 'given_name'){
                                givenName = attribute.Value;
                            }
                            else
                            if( attribute.Name == 'family_name'){
                                familyName = attribute.Value;
                            };

                            if( ii === attributes_arr.length - 1){ // end of loop
                                // CREATE: userName string
                                userName = `${givenName} ${familyName}`;
                                // GIVE: userName to userEngagement_obj[userId] object
                                self.userEngagement_obj[userId].userName = userName;
                            };
                        };

                        if( i === self.payload.users.length - 1){ // end of loop
                            resolve();
                        };
                    };
                });
            };

            function give_videosWatched_toUserEngagementObj(){
                return new Promise((resolve, reject)=>{

                    // RESOLVE & RETURN on no length
                    if( self.payload.individual_videosWatched.length === 0){
                        resolve();
                        return;
                    };

                    for(let i = 0; i < self.payload.individual_videosWatched.length; i++){
                        let videosWatched_obj = self.payload.individual_videosWatched[i];
                        let userId = videosWatched_obj.userId;

                        if( Object.keys(self.userEngagement_obj).includes(userId)){
                            self.userEngagement_obj[userId].videosWatched = videosWatched_obj.amount;
                        };

                        if( i === self.payload.individual_videosWatched.length - 1){ // end of loop
                            resolve();
                        };
                    };
                });
            };
            // END internal functions
        },
        set_userEngagementTable_fromUserEngagementArr: function(){
            return new Promise((resolve, reject)=>{

                let userEngagementArr = this.userEngagement_arr;

                // RESOLVE/RETURN if no data to work on
                if( userEngagementArr.length === 0){
                    resolve();
                    return;
                };

                for(let i = 0; i < userEngagementArr.length; i++){
                    let userObj = userEngagementArr[i];

                    this.userEngagement_table.push([
                        userObj.userName,
                        userObj.lastLogin,
                        userObj.preMeetingSurveys,
                        userObj.postMeetingSurveys,
                        userObj.postsInDiscussionBoard,
                        userObj.preMeetingMaterials,
                        userObj.videosWatched,
                    ]);

                    if( i === userEngagementArr.length - 1){ // end of loop
                        resolve();
                    };
                };
            });
        },
        set_userListTable: function(){
            return new Promise((resolve, reject)=>{

                let users = this.payload.users;
                // RESOLVE/RETURN if no data to work on
                if( users.length === 0){
                    resolve();
                    return;
                };
                for(let i = 0; i < users.length; i++){
                    let userObj = users[i];
                    let attributes = userObj.Attributes;

                    let collectionObj = {
                        givenName: null,
                        familyName: null,
                        email: null,
                        affiliation: null,
                        specialty: null,
                        profession: null,
                        timezone: null,
                        province: null,
                        country: null
                    };

                    for(let ii = 0; ii < attributes.length; ii++){
                        let attribute = attributes[ii];

                        if( attribute.Name == 'given_name'){
                            collectionObj.givenName = attribute.Value;
                        }
                        else
                        if( attribute.Name == 'family_name'){
                            collectionObj.familyName = attribute.Value;
                        }
                        else
                        if( attribute.Name == 'email'){
                            collectionObj.email = attribute.Value;
                        }
                        else
                        if( attribute.Name == 'custom:affiliation'){
                            collectionObj.affiliation = attribute.Value;
                        }
                        else
                        if( attribute.Name == 'custom:hcp_specialty'){
                            collectionObj.specialty = attribute.Value;
                        }
                        else
                        if( attribute.Name == 'custom:hcp_job_profession'){
                            collectionObj.profession = attribute.Value;
                        }
                        else
                        if( attribute.Name == 'custom:timezone'){
                            collectionObj.timezone = attribute.Value;
                        }
                        else
                        if( attribute.Name == 'custom:prov_state'){
                            collectionObj.province = attribute.Value;
                        }
                        else
                        if( attribute.Name == 'custom:country'){
                            collectionObj.country = attribute.Value;
                        };

                        if( ii === attributes.length - 1){ // end of inner loop
                            this.userListTable.push([
                                collectionObj.givenName,
                                collectionObj.familyName,
                                collectionObj.email,
                                collectionObj.affiliation,
                                collectionObj.specialty,
                                collectionObj.profession,
                                collectionObj.timezone,
                                collectionObj.province,
                                collectionObj.country
                            ]);
                        };

                        if( i === users.length - 1
                        && ii === attributes.length - 1){ // end of BOTH loops
                            resolve();
                        };
                    };
                };
            });
        }
    },
    watch: {
        filter_roles: async function(){
            // RESET: metrics and tables data
            await this.reset_dataDumpData();
            // PASS: dataDumpData to payload data
            await this.pass_dataDumpData_toPayload();

            // SET: filters for surveys to empty if no filter roles
            if( this.filter_roles.length == 0){
                this.filter_surveyDataByEvent_items = [];
                this.filter_surveyDataByQuestionSet_items = [];
            }

            // SET: surveyFilters when filter_roles change
            this.set_surveyFilters_fromSurveyDataObjs();
        },
        payload: async function(){

            // RESET: metrics and tables data
            await this.reset_dataDumpData();

            // SET: userListTable
            await this.set_userListTable();

            // SET: userEngagement table
            await this.set_userEngagementObj_fromPayload();
            await this.set_userEngagementArr_fromUserEngagementObj();
            await this.set_userEngagementTable_fromUserEngagementArr();

            await this.set_discussionForumPostsTable_fromPayload();

            await this.set_surveyData_byEventAndQuestionSet_fromPayload();

            this.set_surveyFilters_fromSurveyDataObjs();
        }
    }
}
</script>

<style scoped lang='scss'>

    .wrapper {
        padding: 16px 80px;
    }

    .v-card {
        margin: 8px 0;
        padding: 8px 24px;
    }

    .v-btn {
        margin: 8px;
        background-color: #dbeaf2 !important;
    }

    h2 {
        text-align: left;
    }

    .filterCard {
        background-color: #dbeaf2;
    }

    .surveyDataCards{
        display: flex;

        .side {
            width: 50%;

            &:nth-of-type(2){
                margin-left: 24px;
            }
        }
    }

</style>
